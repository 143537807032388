@import 'assets/styles/core';

.swiper {
  &-button-prev,
  &-button-next {
    width: auto;
    margin-top: calc(-20px - var(--swiper-navigation-size) / 2);

    &::after {
      background-color: $alice-blue;
      opacity: .6;
      padding: 20px 6px;
      font-size: rem-calc(30);
      color: $dark-gray;
    }
  }

  &-button-prev {
    left: 0;

    &::after {
      border-radius: 0 6px 6px 0;
    }
  }

  &-button-next {
    right: 0;

    &::after {
      border-radius: 6px 0 0 6px;
    }
  }
}
